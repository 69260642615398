import { useEffect, useState } from "react";
import "./App.css";
import Background from "./Components/background/Background";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";

function App() {
  const heroData=[
    {text1:"Dive into",text2:"what you love"},
    {text1:"Indulge",text2:"Your passions"},
    {text1:"Give in to",text2:"Your passions"}
  ]
  const [heroCount,setHeroCount] = useState(0);
  const [playStatus,setPlayStatus] = useState(false);

  useEffect(()=>{
setInterval(()=>{
setHeroCount((count)=>{return count===2?0:count+1})
},3000)
  },[])
  return (
    <div className="App">
      <Background heroCount={heroCount} playStatus={playStatus} />
      <Navbar/>
      <Hero
        heroData={heroData[heroCount]}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
        setPlayStatus={setPlayStatus}
      />
    </div>
  );
}

export default App;
